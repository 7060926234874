<template>
  <div>
    <validation-provider
      #default="{ errors }"
      :name="question.title"
      rules="required"
    >
      <b-form-group
        :label="question.title"
        :label-for="'question_' + question.id"
      >
        <v-select
          v-if="question.type_score"
          :id="'question_' + question.id"
          v-model="question.result"
          label="title"
          :options="question.options"
          :placeholder="$t('Seçiniz')"
        />
        <b-form-input
          v-else
          :id="'question_' + question.id"
          v-model="question.result"
          :placeholder="question.title"
        />
      </b-form-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'Question',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    bmaResult() {
      return this.$store.getters['businessModelAnalysis/getBmaResult']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
  methods: {
    selectItem(questionsId, optionId) {
      const result = this.bmaResult
      const item = result.findIndex(data => data.id_ma_questions === questionsId)
      this.bmaResult[item].id_ma_option_group_items = optionId
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
